<template>
  

  <div class="grid">
    <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#274461"
    :is-full-page="fullPage"
  ></loading>
  <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  Users
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  label="Add"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="col-12">
      <div class="card" id="custom_card">
        <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
        <Toast />
        

        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <span class="p-float-label">
              <InputText  
                      format="number" 
                     
                      v-model="mobile_number" 
                      @keypress="isNumber($event)" > 
                  </InputText>
                  <label for="m_no">Mobile Number</label>
                </span>
                
            </div>&nbsp;

            <div class="my-2">
              <span class="p-float-label">
              <Dropdown 
                      style="width:150px" 
                      id="state" 
                      v-model="user_type_search" 
                      :options="user_type_dropdown" 
                      optionLabel="name" 
                      :optionValue="name"  filter
                      :disabled="hide_dropdown"
                      >
              </Dropdown>
              <label for="type">Select Type</label>
            </span>
            </div>&nbsp;

            <div class="my-2">
                <span class="p-float-label">
                  <Dropdown v-if="user_key && (user_key=='Admin' || user_key=='admin')" id="state"  filter  v-model="client_id_search"  style="width:150px" :options="client_id_dropdown" optionLabel="name" :optionValue="value"></Dropdown>
                <label for="client">Select Client</label>
              </span>
              </div>  &nbsp;

            <div class="my-2">
            <Button
                icon="pi pi-search"
                v-tooltip.bottom="'Search'"
                class="btn_light_blue"
                @click="search(1)"
              />
              &nbsp;
              
            </div>
            <div class="my-2">
              <Button class="btn_red" icon="pi pi-refresh"   v-tooltip.bottom="'Refresh'" @click="search(2)" />
            </div>
          </template>

          <!-- <template v-slot:end> -->
            <!-- <Button
                
                icon="pi pi-plus"
                v-tooltip.bottom="'Add'"
                @click="openNew"
              /> -->
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <!-- <json-excel :data="product"> -->
            <!-- <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
             @click="exportCSV"
            /> -->
            <!-- </json-excel> -->
          <!-- </template> -->
        </Toolbar>
       
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        >
        <template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b> Manage User Master</b></h5>
              
              
              
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
            <div style="text-align:right;font-weight:bold"><span>Total Users:</span>{{totalRecords}}</div>	
          </template>
          
          <!-- <Column v-for="(col,index) in columns" :field="col" :header="col.toUpperCase()" :key="index"></Column> -->
          <!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->

          <Column header="Status" style="min-width: 8rem ;text-align: center;" :field="columns[14]" >
            <template #body="{ data }"> 
              <span v-if="data.status=='Active' ||data.status=='active'"><badge class="btn_green" :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge class="btn_yellow" :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge class="btn_red" :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>

          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
           
          <Column header="User Type" :field="columns[9]" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.user_type }}
            </template>
          </Column>
          <Column
            header="First Name"
            style="min-width: 5rem"
            >
            <template #body="{ data }">
              {{ data.first_name }}
            </template>
          </Column>
          
          <Column header="Surname" :field="columns[3]" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.last_name }}
            </template>
          </Column>
          <!-- <Column header="Address" :field="columns[4]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.address }}
            </template>
          </Column> -->
          <!-- <Column header="State" :field="columns[4]">
            <template #body="{ data }">
              {{ data.state }}
            </template>
          </Column> -->
          <!-- <Column header="Disrtict" :field="columns[6]">
            <template #body="{ data }">
              {{ data.city }}
            </template>
          </Column>
          <Column header="Pincode" :field="columns[12]" style="text-align:right;">
            <template #body="{ data }">
              {{ data.pincode }}
            </template>
          </Column> -->
          <Column header="Mobile" :field="columns[9]" style="text-align:right;">
            <template #body="{ data }">
              {{ data.mobile_number }}
            </template>
          </Column>
          <Column header="Email" :field="columns[10]">
            <template #body="{ data }">
              {{ data.email_id }}
            </template>
          </Column>

          <Column header="Action" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <!-- <Button
             
                icon="pi pi-eye"
                v-tooltip.bottom="'View'"
                class="p-button-rounded p-button-success mr-2 btn_green"
                @click="viewProduct(slotProps.data)"
              /> -->
              <Button
                  icon="pi pi-pencil"
                  title="Edit Client"
                  v-tooltip.bottom="'Edit'"
                  class="p-button-rounded p-button-help mr-2 btn_yellow"
                  @click="editProduct(slotProps.data)"
                />
              <!-- <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mt-2 btn_red"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->

              <Button
                icon="pi pi-trash"
                v-tooltip.bottom="'Delete'"
                class="p-button-rounded p-button-warning mt-2 btn_red"
                @click="delete_toggle(slotProps.data)"
              />

              <OverlayPanel ref="del" >
                <div class="flex flex-column gap-3 w-25rem">
                  <h5>Delete User </h5>

                  <div class="col-12">
                    <div class="p-fluid formgrid grid">
                      <div class="field col-12 md:col-12">
                        <span class="p-float-label">
                          <Textarea
                              format="text"
                              v-model="delete_remark"
                            >
                          </Textarea>
                          <label>Remark</label>
                      </span>
                      </div>

                      <div class="field col-12 md:col-4">
                        <Button label="Delete" @click="delete_user"  class="p-button-primary btn_light_blue"/>
                      </div>
                      <div class="field col-12 md:col-4">
                        <Button label="Cancel" @click="Cancel_delete"  class="p-button-danger btn_red"/>
                      </div>

                    </div>
                  </div>

                </div>
              </OverlayPanel>
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>User Form</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="status">Status</label>
                  <Dropdown
                    id="status"
                    v-model="selectstatus"
                    :options="dropdownItems"
                    :value="dropdownItems.value"
                    optionLabel="name"
                    placeholder="Select One"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>

                <div class="field col-12 md:col-2">
                  <label for="firsT_NAME">First Name</label>
                  <InputText
                    id="firsT_NAME"
                    type="text"
                    v-model.trim="product.firsT_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.firsT_NAME }"
                  />
                </div>

                <div class="field col-12 md:col-2">
                  <label for="middeL_NAME">Middle Name</label>
                  <InputText
                    id="middeL_NAME"
                    type="text"
                    v-model.trim="product.middeL_NAME"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.middeL_NAME }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="surname">Lastname</label>

                  <InputText
                    id="surname"
                    type="text"
                    v-model.trim="product.surname"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.surname }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="aadhaR_CARD">Adhar No</label>
                  <InputText
                    id="aadhaR_CARD"
                    type="number"
                    v-model.trim="product.aadhaR_CARD"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.aadhaR_CARD }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="state">State</label>
                  <InputText
                    id="state"
                    type="text"
                    v-model.trim="product.state"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.state }"
                  />
                </div>

                <div class="field col-12 md:col-2">
                  <label for="disrtict">District</label>
                  <InputText
                    id="disrtict"
                    type="text"
                    v-model.trim="product.disrtict"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.disrtict }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="taluka">Taluka</label>
                  <InputText
                    id="taluka"
                    type="text"
                    v-model.trim="product.taluka"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.taluka }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="citY_VILLAGE">City/Village</label>
                  <InputText
                    id="citY_VILLAGE"
                    type="text"
                    v-model.trim="product.citY_VILLAGE"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.citY_VILLAGE }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="pincode">Pincode</label>
                  <InputText
                    id="pincode"
                    type="number"
                    v-model.trim="product.pincode"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.pincode }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <label for="address">Address</label>

                  <Textarea
                    id="address"
                    type="text"
                    v-model.trim="product.address"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.address }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="mobile">Mobile No</label>
                  <InputText
                    id="mobile"
                    type="number"
                    v-model.trim="product.mobile"
                    required="true"
                    autofocus
                    :class="{
                      'p-invalid': submitted && !product.mobile,
                    }"
                  />
                </div>
                <div class="field col-12 md:col-2">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    type="text"
                    v-model.trim="product.email"
                    required="true"
                    autofocus
                    :class="{ 'p-invalid': submitted && !product.email }"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 md:col-6">
            <Button
              v-if="product.admiN_ID"
              label="UPDATE"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              v-else
              label="ADD"
              icon="pi pi-check"
              class="p-button-success mr-1 mb-1"
              @click="adduser"
            />
            <Button
              label="Cancel"
              icon="pi pi-times"
              class="p-button-danger mr-1 mb-1"
              @click="visibleFull = false"
            />
          </div>
        </Sidebar>

        <Dialog
          v-model:visible="productDialog_old"
          :style="{ width: '450px', width: '450px' }"
          header="Year Details"
          :modal="true"
          class="p-fluid"
        >
          <div class="grid">
            <div class="col-12 md:col-12">
              <div class="field">
                <!-- <img
            :src="'images/product/shoes.jpg'"
            :alt="product.image"
            width="150"
            class="mt-0 mx-auto mb-5 block shadow-2"
          /> -->
              </div>
              <div class="field">
                <label for="enrollmenT_YEAR"
                  >Enrollement Year <i style="color: green">(eg.2022)</i></label
                >
                <InputText
                  id="enrollmenT_YEAR"
                  v-model.trim="product.enrollmenT_YEAR"
                  required="true"
                  autofocus
                  :class="{
                    'p-invalid': submitted && !product.enrollmenT_YEAR,
                  }"
                />
              </div>
              <div class="field">
                <label for="exaM_YEAR"
                  >Exam Year <i style="color: green">(eg.2022-23)</i></label
                >
                <InputText
                  id="exaM_YEAR"
                  v-model.trim="product.exaM_YEAR"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.exaM_YEAR }"
                />
                <!-- <small class="p-invalid" v-if="submitted && !product.name"
                  >Name is required.</small
                > -->
              </div>
              <!-- <div class="field">
                <label for="description">Description</label>
                <Textarea
                  id="description"
                  v-model.trim="product.description"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.description }"
                />
              </div>
              <div class="field">
                <label for="link">Video Link</label>
                <InputText
                  id="link"
                  v-model.trim="product.videolink"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.link }"
                />
              </div>
              
              <div class="field">
                <label for="status">Type</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus"
                  :options="dropdownItems"
                  :value="dropdownItems.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus }"
                  required="true"
                ></Dropdown>
              </div>

              <div class="field">
                <label for="status">Status</label>

                <Dropdown
                  id="state"
                  v-model="selectstatus2"
                  :options="dropdownItems2"
                  :value="dropdownItems2.value"
                  optionLabel="name"
                  placeholder="Select One"
                  :class="{ 'p-invalid': submitted && !selectstatus2 }"
                  required="true"
                ></Dropdown>
              </div> -->
            </div>
            <div class="col-12 md:col-6">
              <!-- <Button
                v-if="product.mjyeaR_ID"
                label="UPDATE"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addyear"
               
              />
              <Button
                v-else
                label="ADD"
                icon="pi pi-check"
                class="p-button-success mr-1 mb-1"
                @click="Addyear"
              /> -->
            </div>
            <div class="col-12 md:col-6">
              <Button
                label="Cancel"
                icon="pi pi-times"
                class="p-button-danger mr-1 mb-1"
                @click="productDialog = false"
              />
            </div>
          </div>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.firsT_NAME }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteuser"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
import BreadcrumbService from "../service/BreadcrumbService";
import JsonExcel from "vue-json-excel";

//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      temp_user_id:"",
      delete_remark:"",
      user_key:"",
      client_id_dropdown: [],
      client_id_search:'',
      hide_dropdown:false,

      mobile_number:'',
      page_no:1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      user_type_search:'',
      file_attachment: "",
      user_type_dropdown: [
					{name: 'Rider', code: 'Rider'},
					{name: 'Driver', code: 'Driver'},
					{name: 'Owner', code: 'Owner'},
					{name: 'Driver Owner', code: 'Driver Owner'}
				],
      // dropdownItems: [
      //   { name: "Motivational", value: "Motivational" },
      //   { name: "Interview", value: "Interview" },
      // ],
      dropdownItems: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      // selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
      BreadcrumbService : null,
      breadcrumbHome: {},
      breadcrumbItems: [],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_client_list();
    this.user_key=localStorage.getItem("user_type");

    this.BreadcrumbService = new BreadcrumbService();
    let Breadcrum_data = this.BreadcrumbService.UserMaster()
    this.breadcrumbHome=Breadcrum_data.home;
    this.breadcrumbItems=Breadcrum_data.items;
   
   
 
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;
    if (localStorage.getItem("driver")) 
    {
      this.user_type_search={name:localStorage.getItem("driver"),code:localStorage.getItem("driver")}
      this.hide_dropdown=true;
      localStorage.driver = '';
    }
    localStorage.driver = '';
    

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
           isNumber(evt) {  
      const charCode = evt.which ? evt.which : evt.keyCode;  
      if (  
        charCode > 31 &&  
        (charCode < 48 || charCode > 57) &&  
        charCode !== 46  
      ) {  
        evt.preventDefault();  
      }  
    },  

    async search(count)
    { 
      if (count==2) {
      this.user_type_search='';
      this.mobile_number='';
      this.client_id_search='';
     }
      if(this.mobile_number!='' && this.mobile_number.length!=10)
      {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Valid Mobile Number",
          life: 3000,
        });
        return false;
      }
      await this.get_list();
      await this.get_count();
    },
    get_client_list()
    { 
      var data = { count: false};
      var promise = apis.getclientist(data);
        promise.then((response) => {
          if(response.data.data){
          response.data.data
            .filter(item => item.client_name)
            .map(item => this.client_id_dropdown.push({ name: item.client_name, value: item._id }));
          }
        });
    },
    async viewProduct(product)
    {

      // var data=[product]
      console.log(JSON.stringify(product));
      await localStorage.setItem('savedata',JSON.stringify(product));
    this.$router.push({name:"userProfile"});
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUpload() {
      this.file_attachment = this.$refs.file_attachment.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
    //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var type='';
      if(this.user_type_search!='')
      {
        type=this.user_type_search.code;
      }
      var data = {
        id: localStorage.getItem("id"),
        count: true,
        user_type:type,
        mobile_number:this.mobile_number,
        client_id: this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id")
      };
      this.loading = true;
      var promise = apis.userlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var type='';
      if(this.user_type_search!='')
      {
        type=this.user_type_search.code;
      }
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        user_type:type,
        mobile_number:this.mobile_number,
        client_id: this.client_id_search.value?this.client_id_search.value:localStorage.getItem("client_id")

      };
      this.loading = true;
      var promise = apis.userlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      // this.productDialog = true;
      this.$router.push({ name: "user_add" });
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async adduser() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Select Status",
          life: 3000,
        });
        return false;
      }
      if (!this.product.firsT_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter First Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.middeL_NAME) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Middle Name",
          life: 3000,
        });
        return false;
      }
      if (!this.product.surname) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter LastName",
          life: 3000,
        });
        return false;
      }
       if (!this.product.aadhaR_CARD) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Adhar No",
          life: 3000,
        });
        return false;
      }
      
      if (!this.product.state) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter State",
          life: 3000,
        });
        return false;
      }
      if (!this.product.disrtict) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter District",
          life: 3000,
        });
        return false;
      }
       if (!this.product.taluka) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Taluka",
          life: 3000,
        });
        return false;
      }
      if (!this.product.citY_VILLAGE) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter city/village",
          life: 3000,
        });
        return false;
      }
      if (!this.product.pincode) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Pincode",
          life: 3000,
        });
        return false;
      }
      if (!this.product.address) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter address",
          life: 3000,
        });
        return false;
      }
      if (!this.product.mobile) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter mobile",
          life: 3000,
        });
        return false;
      }
      if (!this.product.email) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter email",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.admiN_ID) {
        var data = {
          Admin_id: this.product.admiN_ID,
          firsT_NAME: this.product.firsT_NAME,
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          disrtict: this.product.disrtict,
          taluka: this.product.taluka,
          citY_VILLAGE: this.product.citY_VILLAGE,
          mobile: parseInt(this.product.mobile),
          email: this.product.email,
          pincode: parseInt(this.product.pincode),
          aadhaR_CARD: parseInt(this.product.aadhaR_CARD),
          status: this.selectstatus.value,
          createD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promise = apis.editusermaster(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var data1 = {
          firsT_NAME: this.product.firsT_NAME,
          middeL_NAME: this.product.middeL_NAME,
          surname: this.product.surname,
          address: this.product.address,
          state: this.product.state,
          disrtict: this.product.disrtict,
          taluka: this.product.taluka,
          citY_VILLAGE: this.product.citY_VILLAGE,
          mobile: parseInt(this.product.mobile),
          email: this.product.email,
          pincode: parseInt(this.product.pincode),
          aadhaR_CARD: parseInt(this.product.aadhaR_CARD),
          status: this.selectstatus.value,
          createD_BY: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promises = apis.addusermaster(data1);
        promises
          .then((response) => {
            this.isLoadingModel = false;
            this.productDialog = false;
            this.$swal(response.data.message);
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      //  this.selectstatus2={name:"",value:""}
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
   async editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };
     // this.productDialog = true;
      this.selectstatus.value = this.product.status;
      this.selectstatus.name = this.product.status;

      // this.selectstatus2.value = this.product.status;
      // this.selectstatus2.name = this.product.status;

      // this.file_attachment = this.product.image;

        await localStorage.setItem('saveuserdata','');
        await localStorage.setItem('saveuserdata',JSON.stringify(product));
        this.$router.push({name:"user_edit"});
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteuser() {
      if (this.product._id) {
        var data = {
          _id: this.product._id,
        };
        this.isLoadingModel = true;
        var promise = apis.deleteusermaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },

    delete_toggle(data) 
    { 
      this.temp_user_id=data._id;
      this.$refs.del.toggle(event);
    },

    delete_user()
    {
      if (this.delete_remark==null || this.delete_remark=="" || this.delete_remark==undefined) 
      {
        this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Remark",
            life: 3000,
        });
        return false;
      }

      if (this.temp_user_id) {
        alert("delete user");
        var data = {
          _id: this.temp_user_id,
          "remark":this.delete_remark

        };
        this.isLoadingModel = true;
        var promise = apis.deleteusermaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {
            this.$swal(responseapi.data.message);
            this.get_list();
            this.get_count();
            this.temp_user_id="";
            this.delete_remark="";
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },

    Cancel_delete()
    {
      this.temp_user_id="";
      this.delete_remark="";
      this.$refs.del.hide();
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    
  async  exportCSV() {
      this.limit=this.totalRecords;
      this.get_list();
     await this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {downloadExcel: JsonExcel},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
